.wrapper {
  background: #f3f3f3;
  flex-flow: column;
  flex: auto;
  align-content: center;
  width: 440px;
  max-height: 137px;
  padding: 27px 27px 18px;
  display: flex;
}

.topLine {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  display: flex;
}

.label {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 18px;
  font-weight: 600;
}

.ticker {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.graph {
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  width: 100%;
  height: 9px;
  margin-bottom: 18px;
}

.graphFill {
  -webkit-user-select: none;
  user-select: none;
  background: #222;
  height: 9px;
}

.viewLiveLink {
  text-align: center;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
