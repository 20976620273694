:local .selectWrapper {
  height: 100%;
  overflow: hidden;
}
:local .selectWrapper select::-ms-expand {
  display: none;
}
:local .select {
  box-shadow: 0 0 0 30px #fff inset !important;
  background: none;
  border: none;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  color: #222;
  width: 100%;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  flex: 1;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: padding-top 0.1s ease;
  padding-right: 45px;
}
:local .select:-ms-expand {
  display: none;
}
:local .select:disabled {
  cursor: not-allowed;
  box-shadow: 0 0 0 30px #ddd inset !important;
}
:local .withFakeNativePlaceholder {
  color: #888;
}
:local .selectAnimatedPlaceholderPadding {
  padding-top: 15px;
}
:local .twoDecoratorPadding {
  padding-right: 63px;
}
:local .threeDecoratorPadding {
  padding-right: 81px;
}