.imageWrapper {
  background-color: #ddd;
  background-position: center;
  background-size: cover;
}

.imageWrapper.small {
  min-height: 165px;
}

.imageWrapper.medium {
  min-height: 260px;
}

.imageWrapper.large {
  min-height: 500px;
}

@media (width <= 568px) {
  .imageWrapper.large {
    min-height: 165px;
  }
}
