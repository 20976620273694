.headerWrapper {
  background-color: #f3f3f3;
  height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.title {
  font-style: none;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
}

.icon {
  height: 24px;
  width: 24px;
  fill: #c2a661;
}

.bodyText {
  text-align: center;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.button {
  width: 315px;
  margin-bottom: 9px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
}

.text {
  font-size: 16px;
  font-weight: 300;
}