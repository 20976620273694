@media (width <= 768px) {
  .wrapper {
    text-align: center;
  }
}

.title {
  composes: overviewAwardsWeblinksTitle from "../styles.scss";
}

.icon {
  flex: none;
  width: 20px;
  height: 20px;
  margin-right: 18px;
}

.itemWrapper {
  align-items: center;
  display: flex;
}

.itemEditWrapper {
  flex-flow: column;
  align-items: flex-start;
}

.link {
  color: #000;
  cursor: pointer;
  margin-bottom: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  transition: color .2s;
}

.link:hover {
  color: #444;
}

@media (hover: none) {
  .link:hover {
    color: #000;
  }
}

.link.isPlaceholder {
  color: #000;
  cursor: pointer;
  text-decoration: underline;
  transition: color .2s;
}

.link.isPlaceholder:hover {
  color: #444;
}

@media (hover: none) {
  .link.isPlaceholder:hover {
    color: #000;
  }
}

@media (width <= 568px) {
  .link {
    margin-bottom: 0;
  }
}

.linkEdit {
  cursor: pointer;
  align-items: center;
  width: 100%;
  display: flex;
}

.linkLabel {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 2 100%;
  font-size: 16px;
  font-weight: 300;
  overflow-x: hidden;
}

.pen {
  fill: #bbb;
  flex: none;
  width: 20px;
  height: 20px;
  margin-left: 18px;
}

.linkEdit:hover .pen {
  fill: #c2a661;
}

.editWrapper {
  width: 100%;
  min-height: 45px;
}
