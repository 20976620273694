.wrapper {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

@media (width >= 569px) {
  .wrapper {
    width: 100%;
    margin-top: 18px;
  }
}

@media (width <= 568px) {
  .wrapper {
    justify-content: center;
    min-width: 30%;
    display: flex;
  }
}
