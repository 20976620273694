.wrapper {
  flex: auto;
  width: 100%;
  margin: 0 auto 9px;
  display: flex;
}

@media (width <= 568px) {
  .wrapper {
    max-height: initial;
    flex-flow: column;
    align-items: center;
  }
}

.desktopSpacer {
  flex: 2 auto;
}

.profileDataWrapper {
  flex-flow: column;
  flex: 2 auto;
  justify-content: flex-start;
  margin: 0 9px;
  display: flex;
}

@media (width <= 568px) {
  .profileDataWrapper {
    align-items: center;
    margin-bottom: 18px;
    margin-left: 0;
  }
}

.nameWrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.name {
  letter-spacing: -.5px;
  letter-spacing: 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 18px 0 27px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  overflow: hidden;
}

@media (width <= 568px) {
  .name {
    text-align: center;
    max-width: 400px;
    overflow: initial;
    white-space: initial;
    text-overflow: initial;
    margin-bottom: 18px;
    font-size: 22px;
  }
}

.tabRow {
  flex: 0 0 50%;
}
