.title {
  composes: title from "../styles.scss";
}

.title.editTitle {
  text-align: center;
}

@media (width >= 569px) {
  .title {
    margin-top: 0;
  }
}

.articleWrapper {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.articleEditWrapper {
  border: 1px solid #ddd;
}

.image {
  width: 100%;
  height: 100%;
}

.articleImage {
  object-fit: cover;
  background-color: #ddd;
  width: 100%;
  height: 187px;
}

@media (width <= 568px) {
  .articleImage {
    height: 135px;
  }
}

.articleImage.edit {
  height: 260px;
}

.articleInfo {
  flex-flow: column;
  flex: 1 0 50%;
  display: flex;
}

.articleTitle {
  word-wrap: break-word;
  text-align: left;
  margin: 18px 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
}

.articleTitle.edit {
  margin: 0;
}

@media (width <= 568px) {
  .articleTitle {
    margin: 0;
  }
}

.editingArticleWrapper {
  composes: articleWrapper;
  max-width: 315px;
  max-height: unset;
  background: #fff;
  border: 1px solid #ddd;
  align-items: center;
  margin: 0 auto;
  padding: 18px;
}

.editingArticleContent {
  flex-direction: column;
  display: flex;
}

.editingButtonWrapper {
  composes: saveCancelButtons from "../styles.scss";
  margin-top: 9px;
}

.editElement {
  padding: 9px 0;
}

.deleteRow {
  color: #000;
  cursor: pointer;
  margin-top: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  text-decoration: underline;
  transition: color .2s;
}

.deleteRow:hover {
  color: #444;
}

@media (hover: none) {
  .deleteRow:hover {
    color: #000;
  }
}

.savingIndicator {
  z-index: 10020;
  opacity: .7;
  background: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.scraperText .scraperAddNew:hover {
  text-decoration: underline;
}

.scraperEditWrapper {
  composes: editingArticleWrapper;
  position: relative;
}

.scraperInput {
  width: 100%;
  margin-bottom: 9px;
}

.saveCancelButtons {
  composes: saveCancelButtons from "../styles.scss";
}

.saveCancelButton {
  composes: saveCancelButton from "../styles.scss";
}

.placeholder {
  text-align: center;
  margin: 0 auto 27px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.addArticleButton {
  max-width: 320px;
}

@media (width <= 568px) {
  .addArticleButton {
    max-width: unset;
  }
}

.addArticleButtonWrapper {
  justify-content: center;
  display: flex;
}

.penIcon {
  fill: #bbb;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 18px;
  right: 18px;
}

.articleName {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

.articlesWrapper {
  margin-bottom: 27px;
}

.articlesWrapper.display {
  margin-bottom: 0;
}

@media (width <= 568px) {
  .articlesWrapper.display :first-child {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    flex-wrap: nowrap;
    overflow-y: hidden;
  }

  .articlesWrapper.display :first-child::-webkit-scrollbar {
    display: none;
  }
}

.titleCounterError {
  color: #c00;
}
