.tabList {
  width: fit-content;
  list-style: none;
  align-items: flex-start;
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  padding: 0;
  margin: 0;
  position: relative;
}
.tabList.underline {
  border-bottom: thin solid #ddd;
}
.tabList.fullWidth {
  width: 100%;
}
.tabList.fullWidth.center {
  justify-content: center;
}
.tabList.nonePadding {
  padding-bottom: 0;
}
.tabList.xSmallPadding {
  padding-bottom: 9px;
}
.tabList.smallPadding {
  padding-bottom: 18px;
}
.tabList.contrast {
  background-color: #fff;
}
.tabList.contrast.underline {
  border-color: #bbb;
}

.container {
  position: relative;
  width: fit-content;
}
.container.left {
  margin: 0;
}
.container.center {
  margin: 0 auto;
}
.container.fullWidth {
  width: 100%;
}
.container.fullWidth.center {
  margin: 0;
}
.container.overflowContainer {
  overflow: scroll;
  max-width: 100vw;
  -webkit-overflow-scrolling: touch;
}
.container.overflowContainer::-webkit-scrollbar {
  display: none;
}

.indicator {
  background-color: #222;
  height: 2px;
  left: 0;
  bottom: 0;
  margin: 0;
  position: absolute;
  transform-origin: left;
  will-change: transform;
  display: none;
}
.indicator.animate {
  transition-property: transform, width;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.indicator.contrast {
  background-color: #fff !important;
}