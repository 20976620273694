.container {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 280px;
}
@media (max-width: 568px) {
  .container {
    max-width: 100%;
  }
}

.containerEditable {
  composes: container;
  height: 100%;
}
.containerEditable .editIcon {
  visibility: visible;
}
.containerEditable.large {
  max-width: 100%;
  margin: 0;
}
@media (max-width: 568px) {
  .containerEditable.large {
    margin: 9px 0;
  }
}

.innerContainer {
  align-items: stretch;
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 9px auto;
  min-width: 200px;
  width: 100%;
  position: relative;
}
.innerContainer.large {
  max-width: 100%;
}
@media (max-width: 568px) {
  .innerContainer {
    margin: 0;
    max-width: 100%;
  }
}

.innerContainerDraggable {
  composes: innerContainer;
  cursor: move;
}

.statusWrapper {
  margin-top: 9px;
}

.imageContainer {
  position: relative;
}

.imageOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #222;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  opacity: 0;
  transition: all 0.3s ease-in;
}
.imageOverlay:hover {
  opacity: 0.7;
  color: #fff;
}