.wrapper {
  height: 100px;
  width: 100px;
  margin: 9px;
  border-radius: 50%;
  background: #ddd;
  background-size: cover;
  background-position: center;
  position: relative;
}
.wrapper.isEditMode {
  cursor: pointer;
}
.wrapper.isEditMode:hover {
  opacity: 0.4;
}
.wrapper.hasError {
  border: 2px solid #cc0000;
}
.wrapper.isSaving {
  cursor: wait;
}

.profilePhotoFrame {
  position: relative;
  height: 100%;
  width: 100%;
}

.profilePhoto {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  position: absolute;
}

.spinner {
  height: 100%;
  width: 100%;
  background-color: #222;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  position: absolute;
  top: 0;
}

.overlay {
  composes: isSaving;
  margin: 5px 0 0 9px;
  user-select: none;
  transition: opacity 0.2s;
  position: absolute;
  bottom: -50px;
  height: 50px;
}
.profilePhotoEdit:hover .overlay {
  opacity: 0.6;
}

.letterWrapper {
  composes: profilePhotoFrame;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 32px;
  font-weight: 300;
  color: #bbb;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4.5px;
}