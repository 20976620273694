.container {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 18px;
}
@media (max-width: 568px) {
  .container {
    margin-bottom: 0;
  }
}
@media (min-width: 569px) {
  .container {
    width: 100%;
    justify-content: space-between;
  }
}

.content {
  display: flex;
  align-items: center;
}
@media (min-width: 569px) {
  .content {
    flex: 2 2 auto;
  }
}

.pin {
  width: 24px;
  height: 24px;
  margin-right: 9px;
  border-bottom: none;
}

.inputWrapper {
  width: 100%;
  flex: 2 2 100%;
}

.labelText {
  position: relative;
}