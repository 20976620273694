.projectName {
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
}

.projectLocation {
  color: #bbb;
  text-transform: uppercase;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 300;
}
