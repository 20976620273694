.wrapper {
  height: 100%;
  padding: 9px 0;
}

.container {
  composes: container from "./EditDesignProjectTile.scss";
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: underline;
  margin: 0;
}
.container:hover {
  color: #444;
}
@media (hover: none) {
  .container:hover {
    color: #000;
  }
}
.container.medium, .container.large {
  max-width: 100%;
  background-image: linear-gradient(to right, #bbb 50%, transparent 50%), linear-gradient(to right, #bbb 50%, transparent 50%), linear-gradient(to bottom, #bbb 50%, transparent 50%), linear-gradient(to bottom, #bbb 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 1px, 15px 1px, 1px 15px, 1px 15px;
}
.container.small {
  max-width: unset;
  border: 1px solid #ddd;
  height: 100%;
}
.container.medium {
  margin-top: 45px;
  height: 209px;
}
.container.large {
  height: 367px;
}

.innerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.addProjectIcon {
  height: 30px;
  width: 30px;
}
.addProjectIcon.large {
  fill: #bbb;
  height: 56px;
  width: 56px;
}

.addProject {
  margin-top: 9px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}