.badge {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  color: #fff;
  padding: 2px 9px;
  border-radius: 2px;
  margin-bottom: 9px;
}
.badge.isDraft {
  background-color: #222;
}
.badge.isNotVisible {
  background-color: #bbb;
}