.dropdownContainer {
  box-shadow: 0px 1px 2px 0px #888;
}

.option {
  padding: 9px 18px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}
.option:hover {
  cursor: pointer;
}
.option.highlighted {
  background-color: #f3f3f3;
}

.datalist {
  list-style-type: none;
  margin: 0;
  padding: 0;
}