.projectName {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
}

.projectLocation {
  font-size: 14px;
  font-weight: 300;
  color: #bbb;
  text-transform: uppercase;
  margin-top: 5px;
}