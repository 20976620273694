.option {
  cursor: pointer;
  padding: 9px 18px;
  text-decoration: none;
  position: relative;
}

.optionBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -10;
}
.optionBackdrop.optionHighlight {
  background-color: rgba(221, 221, 221, 0.9);
}

.optionSelected {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
}