.message {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
  box-sizing: border-box;
  outline: none;
  resize: none;
  width: 100% !important;
  height: 100% !important;
  padding: 18px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #fff !important;
  background-image: none !important;
  border: thin solid #ddd !important;
  border-radius: 0px !important;
  box-shadow: 0 0 0 3000px #fff inset !important;
  transition: border-color 0.15s ease-in;
}
.message:disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  background: #ddd !important;
}
.message:focus {
  border: thin solid #ddd !important;
  border-color: #222 !important;
  outline: none !important;
  box-shadow: 0 0 0 3000px #fff inset !important;
}
.message::placeholder {
  color: #666;
}

.error {
  border-color: #cc0000 !important;
}
.error:focus {
  border-color: #cc0000 !important;
}

.noBorder {
  border: none !important;
}
.noBorder:focus {
  border: none !important;
}

.hiddenPlaceholder {
  display: none;
}