.topWrapper {
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 36px;
  display: flex;
}

.title {
  composes: title from "../styles.scss";
  margin: 0;
}

.tileWrapper {
  position: relative;
}

.viewAllLink {
  white-space: nowrap;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

@media (width <= 568px) {
  .viewAllLink {
    margin: 0 auto;
    padding-top: 9px;
    display: block;
  }
}
