.option {
  cursor: pointer;
  padding: 9px 18px;
  text-decoration: none;
  position: relative;
}

.optionBackdrop {
  z-index: -10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.optionBackdrop.optionHighlight {
  background-color: #dddddde6;
}

.optionSelected {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}
