.fixedMaxWidth {
  margin: 0 auto;
  max-width: 1440px;
  width: 94%;
}

.title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 54px auto 36px;
  width: 100%;
  line-height: 1;
  text-align: left;
}
@media (max-width: 568px) {
  .title {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.5px;
    margin: 36px auto 27px;
  }
}

.saveCancelButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.saveCancelButtons :first-child {
  margin-right: 5px;
}
.saveCancelButtons :last-child {
  margin-left: 5px;
}

.saveCancelButton {
  flex: 1 1 50%;
}

.viewAllWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 27px auto 49px;
}
.viewAllWrapper:after {
  background: #ddd;
  content: "";
  height: 1px;
  left: 0;
  margin-top: 32px;
  position: absolute;
  width: 100%;
}

.viewAllButton {
  background: #fff;
  border: 1px solid #ddd;
  padding: 4.5px;
  z-index: 1;
}

.overviewAwardsWeblinksTitle {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 18px;
  text-align: left;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .overviewAwardsWeblinksTitle {
    font-size: 16px;
    font-weight: 300;
  }
}

.rowWrapper {
  composes: fixedMaxWidth from "./styles.scss";
  margin: 45px auto;
  padding-bottom: 45px;
}
.rowWrapper:not(:last-child)::after {
  position: absolute;
  left: 0;
  content: " ";
  display: block;
  height: 45px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}
@media (max-width: 568px) {
  .rowWrapper:not(:last-child)::after {
    height: 36px;
  }
}
@media (max-width: 568px) {
  .rowWrapper {
    width: unset;
    margin: 0 18px;
    padding-bottom: 36px;
  }
  .rowWrapper:first-child {
    padding-top: 36px;
  }
}