.paginationContainer {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  text-align: center;
}

.pageList {
  display: flex;
  position: relative;
}

.pageInputContainer {
  padding: 9px 14px;
  border: 1px solid #ddd;
  position: absolute;
}

input.pageInput {
  width: 75px;
}

.paginationItem {
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  width: 48px;
  text-align: center;
  margin-right: 15px;
}
@media (max-width: 568px) {
  .paginationItem {
    margin-right: 9px;
    width: 36px;
    height: 36px;
  }
}

.ellipsisContainer {
  composes: paginationItem;
  position: relative;
}
@media (max-width: 568px) {
  .ellipsisContainer {
    width: 18px;
    height: 18px;
  }
}

.page {
  text-decoration: none;
  display: block;
  padding: 10px 0;
  color: #222;
  width: 100%;
  background-color: #fff;
  border: 1px solid #bbb;
}
.page:hover {
  border-color: #222;
}

.activeItem .page {
  cursor: default;
  background: #f3f3f3;
}
.activeItem .page:hover {
  border-color: #bbb;
  color: inherit;
}

.ellipsis {
  text-decoration: none;
  display: block;
  padding: 10px 0;
  color: #222;
  width: 100%;
  width: 100%;
}

.pageInputContainer {
  left: -28px;
  bottom: 48px;
}
@media (max-width: 568px) {
  .pageInputContainer {
    display: none;
  }
}

.prevArrowItem {
  margin-right: 18px;
}
@media (max-width: 568px) {
  .prevArrowItem {
    position: absolute;
    bottom: 41px;
    left: -7px;
  }
}

.nextArrowItem {
  margin-left: 5px;
}
@media (max-width: 568px) {
  .nextArrowItem {
    position: absolute;
    bottom: 41px;
    right: -7px;
  }
}

.arrowLink {
  text-decoration: none;
  display: block;
  padding: 10px 0;
  color: #222;
  width: 100%;
}

.arrowWrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  display: flex;
}

.arrow {
  margin: 2px 10px 0 0;
  width: 15px;
  height: 15px;
}

.nextArrowItem .arrow {
  margin-right: 0;
  margin-left: 10px;
}