.wrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .wrapper {
    top: 5px;
  }
}
@media (min-width: 569px) {
  .wrapper {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 36px;
  }
}

.contentWrapper {
  flex: 2 2 auto;
  display: flex;
  align-items: center;
  position: relative;
}

.inputWrapper {
  width: 100%;
}

.iconWrapper {
  height: 24px;
  width: 24px;
  margin-right: 9px;
}