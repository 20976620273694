.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 36px;
}

.title {
  composes: title from "../styles.scss";
  margin: 0;
}

.tileWrapper {
  position: relative;
}

.viewAllLink {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  white-space: nowrap;
}
@media (max-width: 568px) {
  .viewAllLink {
    display: block;
    margin: 0 auto;
    padding-top: 9px;
  }
}