.listItemWrapper {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.listItemWrapper:not(:last-of-type).medium {
  margin-right: 27px;
}
.listItemWrapper:not(:last-of-type).large {
  margin-right: 36px;
}
.listItemWrapper:not(:last-of-type).small {
  margin-right: 18px;
}

.tab {
  display: inline-block;
  padding: 0;
  vertical-align: top;
  position: relative;
  color: #666;
}
.tab[data-focus-visible-added] {
  outline: #666 solid 2px;
}
.tab.mediumBody {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}
.tab.mediumBodyHeavy {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
}
.tab.smallBody {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
.tab.smallBodyHeavy {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
}
.tab.smallSubheader {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
}
.tab.smallSubheaderHeavy {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}
.tab.mediumSubheader {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
}
.tab.mediumSubheaderHeavy {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}
.tab:hover, .tab.active {
  color: #222;
}
.tab.contrast {
  color: #bbb;
}
.tab.contrast.active, .tab.contrast:hover {
  color: #fff;
}