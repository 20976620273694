.tilesContainer {
  margin: 0 auto;
  padding: 36px 0;
  position: relative;
}

.tileWrapper {
  flex-basis: 25%;
  max-width: 25%;
  position: relative;
}
@media (max-width: 568px) {
  .tileWrapper {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.favoritesIconsWrap {
  padding: 9px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
@media (max-width: 568px) {
  .favoritesIconsWrap {
    transform: scale(0.8);
  }
}

.favoritesIconContainer {
  height: 26px;
  margin-left: auto;
  margin-top: 9px;
  position: relative;
  width: 26px;
}

.connectNewFolder {
  cursor: pointer;
  height: 25px;
  position: absolute;
  right: 9px;
  top: 18px;
  width: 25px;
}

.tooltipContent {
  cursor: pointer;
  width: 100%;
}

.noItemsContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 27px;
  min-height: 300px;
  width: 100%;
  flex-direction: column;
}

.noItemsContent {
  text-align: center;
}

.noItemsTitle {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin-top: 27px;
}
@media (max-width: 768px) {
  .noItemsTitle {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.5px;
  }
}

.connectDifferentFolderButton {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  width: 100%;
}

.noItemsMessage {
  font-size: 14px;
  font-weight: 300;
}

.paginationRow {
  width: 100%;
}