.badge {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  border-radius: 2px;
  margin-bottom: 9px;
  padding: 2px 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}

.badge.isDraft {
  background-color: #222;
}

.badge.isNotVisible {
  background-color: #bbb;
}
