@media (width <= 568px) {
  :local .nonMobileWrapper {
    display: none;
  }
}

:local .isMobileWrapper {
  display: none;
}

@media (width <= 568px) {
  :local .isMobileWrapper {
    display: block;
  }
}
