.projectsContainer {
  composes: fixedMaxWidth from "./styles.scss";
  margin: 48px auto;
  position: relative;
}
.projectsContainer.display {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .projectsContainer {
    flex-direction: column;
  }
}

.noProjectsContainer {
  max-width: 414px;
  margin: 117px auto;
  text-align: center;
}

.emptyProjectIconContainer {
  height: 63px;
  width: 63px;
}

.startAddingProjectsHeader {
  font-size: 36px;
  margin: 18px;
}

.startAddingProjectsSubheader {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 27px;
}