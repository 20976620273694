.editContainer {
  border: 1px solid #0000;
  max-height: 100%;
  padding: 0 9px;
  position: relative;
}

.editContainer:hover {
  border: 1px solid #ddd;
}

@media (width <= 568px) {
  .editContainer {
    margin-bottom: 18px;
  }
}

.wrapper {
  padding-bottom: 18px;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  position: relative;
}

.wrapper.edit {
  margin-right: 27px;
  padding: 18px;
}

.paragraph {
  padding-bottom: 9px;
}

.paragraph:last-child {
  padding-bottom: 0;
}

.pen {
  fill: #bbb;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 18px;
  right: 9px;
}

@media (width <= 568px) {
  .pen {
    top: 0;
    right: 0;
  }
}

.aboutExpanded {
  padding-bottom: 9px;
}

.title {
  composes: title from "../styles.scss";
}

@media (width >= 569px) {
  .title {
    margin-top: 0;
  }
}

.tooltipBody {
  hyphens: none;
}
