.wrapper {
  background: #f3f3f3;
  width: 440px;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  padding: 27px 27px 18px;
  align-content: center;
  max-height: 137px;
}

.topLine {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.label {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 600;
}

.ticker {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.graph {
  user-select: none;
  background: #fff;
  margin-bottom: 18px;
  height: 9px;
  width: 100%;
}

.graphFill {
  user-select: none;
  background: #222;
  height: 9px;
}

.viewLiveLink {
  text-align: center;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}