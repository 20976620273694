.wrapper {
  display: flex;
  margin: 0 auto;
  flex: 1 1 auto;
  width: 100%;
  margin-bottom: 9px;
}
@media (max-width: 568px) {
  .wrapper {
    flex-flow: column nowrap;
    max-height: initial;
    align-items: center;
  }
}

.desktopSpacer {
  flex: 2 1 auto;
}

.profileDataWrapper {
  display: flex;
  flex-flow: column nowrap;
  flex: 2 1 auto;
  margin: 0 9px;
  justify-content: flex-start;
}
@media (max-width: 568px) {
  .profileDataWrapper {
    margin-left: 0;
    align-items: center;
    margin-bottom: 18px;
  }
}

.nameWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 18px 0 27px;
  line-height: 1.2;
  letter-spacing: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media (max-width: 568px) {
  .name {
    text-align: center;
    font-size: 22px;
    max-width: 400px;
    margin-bottom: 18px;
    overflow: initial;
    white-space: initial;
    text-overflow: initial;
  }
}

.tabRow {
  flex: 0 0 50%;
}