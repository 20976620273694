.wrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}
@media (min-width: 569px) {
  .wrapper {
    margin-top: 18px;
    width: 100%;
  }
}
@media (max-width: 568px) {
  .wrapper {
    min-width: 30%;
    display: flex;
    justify-content: center;
  }
}