.tilesContainer {
  margin: 0 auto;
  padding: 36px 0;
  position: relative;
}

.tileWrapper {
  flex-basis: 25%;
  max-width: 25%;
  position: relative;
}

@media (width <= 568px) {
  .tileWrapper {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.favoritesIconsWrap {
  z-index: 10;
  padding: 9px;
  position: absolute;
  top: 0;
  right: 0;
}

@media (width <= 568px) {
  .favoritesIconsWrap {
    transform: scale(.8);
  }
}

.favoritesIconContainer {
  width: 26px;
  height: 26px;
  margin-top: 9px;
  margin-left: auto;
  position: relative;
}

.connectNewFolder {
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 18px;
  right: 9px;
}

.tooltipContent {
  cursor: pointer;
  width: 100%;
}

.noItemsContainer {
  flex-flow: column wrap;
  align-items: center;
  width: 100%;
  min-height: 300px;
  margin-top: 27px;
  display: flex;
}

.noItemsContent {
  text-align: center;
}

.noItemsTitle {
  letter-spacing: -.5px;
  margin-top: 27px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (width <= 768px) {
  .noItemsTitle {
    letter-spacing: -.5px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }
}

.connectDifferentFolderButton {
  justify-content: center;
  width: 100%;
  margin-top: 45px;
  display: flex;
}

.noItemsMessage {
  font-size: 14px;
  font-weight: 300;
}

.paginationRow {
  width: 100%;
}
