.wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 569px) {
  .wrapper {
    width: 208px;
  }
}
@media (max-width: 568px) {
  .wrapper {
    flex-flow: row nowrap;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 9px;
  }
}

@media (min-width: 569px) {
  .buttonWrapper {
    width: 100%;
  }
}