.wrapper {
  height: 100%;
  padding: 9px 0;
}

.container {
  composes: container from "./EditDesignProjectTile.scss";
  color: #000;
  cursor: pointer;
  margin: 0;
  text-decoration: underline;
  transition: color .2s;
}

.container:hover {
  color: #444;
}

@media (hover: none) {
  .container:hover {
    color: #000;
  }
}

.container.medium, .container.large {
  background-image: linear-gradient(to right, #bbb 50%, #0000 50%), linear-gradient(to right, #bbb 50%, #0000 50%), linear-gradient(#bbb 50%, #0000 50%), linear-gradient(#bbb 50%, #0000 50%);
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 1px, 15px 1px, 1px 15px, 1px 15px;
  max-width: 100%;
}

.container.small {
  max-width: unset;
  border: 1px solid #ddd;
  height: 100%;
}

.container.medium {
  height: 209px;
  margin-top: 45px;
}

.container.large {
  height: 367px;
}

.innerContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.addProjectIcon {
  width: 30px;
  height: 30px;
}

.addProjectIcon.large {
  fill: #bbb;
  width: 56px;
  height: 56px;
}

.addProject {
  margin-top: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}
