.wrapper {
  margin-bottom: 27px;
}
@media (max-width: 768px) {
  .wrapper {
    text-align: center;
  }
}

.title {
  composes: overviewAwardsWeblinksTitle from "../styles.scss";
}

.awardWrapper {
  display: flex;
}

.award {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 9px;
  position: relative;
  display: flex;
  align-items: center;
}
.award.deleteInProgress {
  pointer-events: none;
}

.awardEdit {
  cursor: pointer;
}
.awardEdit:hover {
  color: #c2a661;
}

.trashCanWrapper {
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 9px;
  cursor: pointer;
}

.trashCan {
  width: 20px;
  height: 20px;
  fill: #bbb;
}
.trashCan:hover {
  fill: #cc0000;
}

.editWrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  border: 1px solid #ddd;
  padding: 18px;
  margin-bottom: 9px;
}

.buttonRow {
  composes: saveCancelButtons from "../styles.scss";
  margin-top: 9px;
}

.button {
  composes: saveCancelButton from "../styles.scss";
}

.addAward {
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: underline;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
}
.addAward:hover {
  color: #444;
}
@media (hover: none) {
  .addAward:hover {
    color: #000;
  }
}

.ribbon {
  width: 20px;
  height: 20px;
  margin-right: 18px;
  flex-shrink: 0;
}