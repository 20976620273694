@media (max-width: 568px) {
  :local .nonMobileWrapper {
    display: none;
  }
}
:local .isMobileWrapper {
  display: none;
}
@media (max-width: 568px) {
  :local .isMobileWrapper {
    display: block;
  }
}