@media (max-width: 768px) {
  .wrapper {
    text-align: center;
  }
}

.title {
  composes: overviewAwardsWeblinksTitle from "../styles.scss";
}

.icon {
  height: 20px;
  width: 20px;
  margin-right: 18px;
  flex: 0 0 auto;
}

.itemWrapper {
  display: flex;
  align-items: center;
}

.itemEditWrapper {
  flex-flow: column nowrap;
  align-items: flex-start;
}

.link {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
  margin-bottom: 18px;
}
.link:hover {
  color: #444;
}
@media (hover: none) {
  .link:hover {
    color: #000;
  }
}
.link.isPlaceholder {
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: underline;
}
.link.isPlaceholder:hover {
  color: #444;
}
@media (hover: none) {
  .link.isPlaceholder:hover {
    color: #000;
  }
}
@media (max-width: 568px) {
  .link {
    margin-bottom: 0;
  }
}

.linkEdit {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.linkLabel {
  font-size: 16px;
  font-weight: 300;
  overflow-x: hidden;
  flex: 1 2 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pen {
  height: 20px;
  width: 20px;
  fill: #bbb;
  flex: 0 0 auto;
  margin-left: 18px;
}
.linkEdit:hover .pen {
  fill: #c2a661;
}

.editWrapper {
  min-height: 45px;
  width: 100%;
}