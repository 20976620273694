:local .wrapper {
  position: relative;
}

:local .dropdown {
  z-index: 300;
  box-sizing: border-box;
  background-color: #fff;
  border: none;
  border-left: 1px solid #222;
  border-right: 1px solid #222;
  position: absolute;
}

:local .matchContainerWidth {
  width: 100%;
}

:local .dropdownExpanded {
  border: 1px solid #222;
}

:local .spillOutOfWindow {
  position: fixed;
}

:local .dropdownShadow {
  box-shadow: 0 1px 1px #888;
}

:local .hideDropdownBorderTop {
  border-top: none !important;
}

:local .hideDropdownBorderBottom {
  border-bottom: none;
}

:local .reverseDirection {
  bottom: 0;
}

:local .alignRight {
  right: 0;
}

:local .detachedMode {
  filter: drop-shadow(2px 4px 4px #888);
  border: none;
  width: 130%;
  top: 9px;
}

:local .borderless {
  border-radius: 5px;
  box-shadow: 1px 2px 8px #00000040;
  border: none !important;
}
