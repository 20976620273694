.container {
  align-items: center;
  margin-bottom: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  display: flex;
  position: relative;
}

@media (width <= 568px) {
  .container {
    margin-bottom: 0;
  }
}

@media (width >= 569px) {
  .container {
    justify-content: space-between;
    width: 100%;
  }
}

.content {
  align-items: center;
  display: flex;
}

@media (width >= 569px) {
  .content {
    flex: 2 2 auto;
  }
}

.pin {
  border-bottom: none;
  width: 24px;
  height: 24px;
  margin-right: 9px;
}

.inputWrapper {
  flex: 2 2 100%;
  width: 100%;
}

.labelText {
  position: relative;
}
