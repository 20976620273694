.message {
  box-sizing: border-box;
  resize: none;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  transition: border-color .15s ease-in;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #fff !important;
  background-image: none !important;
  border: thin solid #ddd !important;
  border-radius: 0 !important;
  width: 100% !important;
  height: 100% !important;
  padding: 18px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  box-shadow: inset 0 0 0 3000px #fff !important;
}

.message:disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  background: #ddd !important;
}

.message:focus {
  border: thin solid #222 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 3000px #fff !important;
}

.message::placeholder {
  color: #666;
}

.error, .error:focus {
  border-color: #c00 !important;
}

.noBorder, .noBorder:focus {
  border: none !important;
}

.hiddenPlaceholder {
  display: none;
}
