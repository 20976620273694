.wrapper {
  flex-flow: row;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 10px;
}

@media (width <= 768px) {
  .wrapper {
    margin-top: 9px;
  }
}

.breadcrumbItem {
  padding-right: 10px;
  font-size: 13px;
  color: #888 !important;
}
