.projectsContainer {
  composes: fixedMaxWidth from "./styles.scss";
  margin: 48px auto;
  position: relative;
}

.projectsContainer.display {
  flex-wrap: wrap;
  display: flex;
}

@media (width <= 768px) {
  .projectsContainer {
    flex-direction: column;
  }
}

.noProjectsContainer {
  text-align: center;
  max-width: 414px;
  margin: 117px auto;
}

.emptyProjectIconContainer {
  width: 63px;
  height: 63px;
}

.startAddingProjectsHeader {
  margin: 18px;
  font-size: 36px;
}

.startAddingProjectsSubheader {
  margin-bottom: 27px;
  font-size: 18px;
  font-weight: 300;
}
