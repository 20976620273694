.container.large {
  flex: 1;
}

.innerContainer {
  background: #fff;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-decoration: none;
  display: block;
}

.innerContainer.large {
  max-width: 100%;
}

@media (width <= 768px) {
  .innerContainer {
    max-width: 100%;
  }
}

.detailsWrapper {
  min-height: 90px;
  padding: 9px 15px;
  position: relative;
}

.title {
  letter-spacing: -.5px;
  text-align: center;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (width <= 568px) {
  .title {
    letter-spacing: -.5px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }
}
