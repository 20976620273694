.wrapper {
  background-color: #f3f3f3;
  border-bottom: thin solid #ddd;
  flex-flow: column;
  place-content: stretch flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.wrapper.isEditMode {
  background-color: #fff;
}

.firmInfoContainer {
  z-index: 2;
  width: 100%;
  position: relative;
  background-position: center !important;
  background-size: cover !important;
}

.headerContent {
  composes: fixedMaxWidth from "./styles.scss";
  z-index: 2;
  flex-flow: column;
  justify-content: space-between;
  padding: 10px 0 0;
  display: flex;
  position: relative;
}

@media (width <= 568px) {
  .headerContent {
    padding: 0;
  }
}

.uploadIconWrapper {
  cursor: pointer;
  color: #fff;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  right: 0;
  position: absolute !important;
}

@media (width <= 568px) {
  .uploadIconWrapper {
    justify-content: center;
    width: 100%;
    margin-top: 7px;
  }
}

.imageUploadSvg {
  width: 30px;
  height: 30px;
  margin-bottom: 9px;
  margin-left: 9px;
}

.photoOverlay {
  content: "";
  z-index: -1;
  background: url("https://a.1stdibscdn.com/assets/images/profile/darken_overlay_tile.png");
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.splashPhotoError {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  position: absolute;
  inset: 27px;
}
