.wrapper {
  background: #ddd center / cover;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 9px;
  position: relative;
}

.wrapper.isEditMode {
  cursor: pointer;
}

.wrapper.isEditMode:hover {
  opacity: .4;
}

.wrapper.hasError {
  border: 2px solid #c00;
}

.wrapper.isSaving {
  cursor: wait;
}

.profilePhotoFrame {
  width: 100%;
  height: 100%;
  position: relative;
}

.profilePhoto {
  object-fit: cover;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;
}

.spinner {
  color: #fff;
  opacity: .6;
  background-color: #222;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
}

.overlay {
  composes: isSaving;
  -webkit-user-select: none;
  user-select: none;
  height: 50px;
  margin: 5px 0 0 9px;
  transition: opacity .2s;
  position: absolute;
  bottom: -50px;
}

.profilePhotoEdit:hover .overlay {
  opacity: .6;
}

.letterWrapper {
  composes: profilePhotoFrame;
  color: #bbb;
  justify-content: center;
  align-items: center;
  padding-top: 4.5px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 32px;
  font-weight: 300;
  display: flex;
}
