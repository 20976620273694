.listItemWrapper {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.listItemWrapper:not(:last-of-type).medium {
  margin-right: 27px;
}

.listItemWrapper:not(:last-of-type).large {
  margin-right: 36px;
}

.listItemWrapper:not(:last-of-type).small {
  margin-right: 18px;
}

.tab {
  vertical-align: top;
  color: #666;
  padding: 0;
  display: inline-block;
  position: relative;
}

.tab[data-focus-visible-added] {
  outline: 2px solid #666;
}

.tab.mediumBody {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.tab.mediumBodyHeavy {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

.tab.smallBody {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.tab.smallBodyHeavy {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.tab.smallSubheader {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}

.tab.smallSubheaderHeavy {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 600;
}

.tab.mediumSubheader {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.tab.mediumSubheaderHeavy {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.tab:hover, .tab.active {
  color: #222;
}

.tab.contrast {
  color: #bbb;
}

.tab.contrast.active, .tab.contrast:hover {
  color: #fff;
}
