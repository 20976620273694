.editContainer {
  border: 1px solid transparent;
  max-height: 100%;
  position: relative;
  padding: 0 9px;
}
.editContainer:hover {
  border: 1px solid #ddd;
}
@media (max-width: 568px) {
  .editContainer {
    margin-bottom: 18px;
  }
}

.wrapper {
  font-size: 16px;
  font-weight: 300;
  position: relative;
  line-height: 26px;
  padding-bottom: 18px;
}
.wrapper.edit {
  padding: 18px;
  margin-right: 27px;
}

.paragraph {
  padding-bottom: 9px;
}
.paragraph:last-child {
  padding-bottom: 0;
}

.pen {
  position: absolute;
  right: 9px;
  top: 18px;
  height: 20px;
  width: 20px;
  fill: #bbb;
}
@media (max-width: 568px) {
  .pen {
    top: 0;
    right: 0;
  }
}

.aboutExpanded {
  padding-bottom: 9px;
}

.title {
  composes: title from "../styles.scss";
}
@media (min-width: 569px) {
  .title {
    margin-top: 0;
  }
}

.tooltipBody {
  hyphens: none;
}