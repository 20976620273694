.wrapper {
  border: 1px solid #ddd;
  flex-flow: column;
  width: 100%;
  min-width: 200px;
  height: 100%;
  margin: 9px auto;
  display: flex;
}

.imageWrapper {
  width: 100%;
}

.bottomWrapper {
  justify-content: space-between;
  padding: 18px 18px 0;
  display: flex;
}

.content {
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  display: flex;
}

.iconsContainer {
  justify-content: space-between;
  width: 54px;
  height: 20px;
  display: flex;
}

.iconWrapper {
  flex-shrink: 0;
  display: flex;
}

.icon {
  fill: #666;
  width: 20px;
  height: 20px;
}
