.wrapper {
  margin-bottom: 27px;
}

@media (width <= 768px) {
  .wrapper {
    text-align: center;
  }
}

.title {
  composes: overviewAwardsWeblinksTitle from "../styles.scss";
}

.awardWrapper {
  display: flex;
}

.award {
  align-items: center;
  margin-bottom: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  display: flex;
  position: relative;
}

.award.deleteInProgress {
  pointer-events: none;
}

.awardEdit {
  cursor: pointer;
}

.awardEdit:hover {
  color: #c2a661;
}

.trashCanWrapper {
  cursor: pointer;
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 9px;
}

.trashCan {
  fill: #bbb;
  width: 20px;
  height: 20px;
}

.trashCan:hover {
  fill: #c00;
}

.editWrapper {
  border: 1px solid #ddd;
  margin-bottom: 9px;
  padding: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.buttonRow {
  composes: saveCancelButtons from "../styles.scss";
  margin-top: 9px;
}

.button {
  composes: saveCancelButton from "../styles.scss";
}

.addAward {
  color: #000;
  cursor: pointer;
  align-items: center;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  text-decoration: underline;
  transition: color .2s;
  display: flex;
}

.addAward:hover {
  color: #444;
}

@media (hover: none) {
  .addAward:hover {
    color: #000;
  }
}

.ribbon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 18px;
}
