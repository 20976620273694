.promptText {
  text-align: center;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.buttonWrapper {
  margin-top: 27px;
  display: flex;
}

.cancelButton {
  margin-right: 9px;
}

.deleteButton {
  margin-left: 9px;
}
