.title {
  composes: title from "../styles.scss";
}

.rowWrapper {
  composes: rowWrapper from "../OverviewRoot.scss";
}

.feedWrapper {
  flex-flow: wrap;
  justify-content: space-between;
  max-width: 470px;
  height: 435px;
  margin: 0 auto;
  padding-bottom: 18px;
  display: flex;
  position: relative;
}

@media (width <= 768px) {
  .feedWrapper {
    margin: 0 -10px;
  }
}

.imageContainer {
  position: relative;
}

@media (width <= 768px) {
  .imageContainer {
    max-width: 110px;
    margin-bottom: 9px;
  }
}

.image {
  width: 100%;
}

@media (width <= 768px) {
  .image {
    height: auto;
    display: block;
  }
}

.description {
  text-align: center;
  font-size: 18px;
  font-weight: 300;
}

.buttonContainer {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 63px;
  display: flex;
}

.button {
  justify-content: center;
  align-items: center;
  display: flex;
}

.buttonIcon {
  fill: #fff;
  width: 18px;
  height: 18px;
  margin-right: 18px;
}

.disconnectRow {
  justify-content: center;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.disconnectModalCopy {
  text-align: center;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.disconnectModalErrorCopy {
  composes: disconnectModalCopy;
  color: #c00;
}

.disconnectButtonsWrapper {
  justify-content: space-between;
  width: 100%;
  padding-top: 18px;
  display: flex;
}

.disconnectButton {
  flex: 50%;
}

.disconnectButton:first-child {
  margin-right: 4.5px;
}

.disconnectButton:last-child {
  margin-left: 4.5px;
}
