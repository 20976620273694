.paginationContainer {
  text-align: center;
  justify-content: center;
  margin: 40px 0;
  display: flex;
}

.pageList {
  display: flex;
  position: relative;
}

.pageInputContainer {
  border: 1px solid #ddd;
  padding: 9px 14px;
  position: absolute;
}

input.pageInput {
  width: 75px;
}

.paginationItem {
  cursor: pointer;
  text-align: center;
  width: 48px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 300;
}

@media (width <= 568px) {
  .paginationItem {
    width: 36px;
    height: 36px;
    margin-right: 9px;
  }
}

.ellipsisContainer {
  composes: paginationItem;
  position: relative;
}

@media (width <= 568px) {
  .ellipsisContainer {
    width: 18px;
    height: 18px;
  }
}

.page {
  color: #222;
  background-color: #fff;
  border: 1px solid #bbb;
  width: 100%;
  padding: 10px 0;
  text-decoration: none;
  display: block;
}

.page:hover {
  border-color: #222;
}

.activeItem .page {
  cursor: default;
  background: #f3f3f3;
}

.activeItem .page:hover {
  color: inherit;
  border-color: #bbb;
}

.ellipsis {
  color: #222;
  width: 100%;
  padding: 10px 0;
  text-decoration: none;
  display: block;
}

.pageInputContainer {
  bottom: 48px;
  left: -28px;
}

@media (width <= 568px) {
  .pageInputContainer {
    display: none;
  }
}

.prevArrowItem {
  margin-right: 18px;
}

@media (width <= 568px) {
  .prevArrowItem {
    position: absolute;
    bottom: 41px;
    left: -7px;
  }
}

.nextArrowItem {
  margin-left: 5px;
}

@media (width <= 568px) {
  .nextArrowItem {
    position: absolute;
    bottom: 41px;
    right: -7px;
  }
}

.arrowLink {
  color: #222;
  width: 100%;
  padding: 10px 0;
  text-decoration: none;
  display: block;
}

.arrowWrapper {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  display: flex;
}

.arrow {
  width: 15px;
  height: 15px;
  margin: 2px 10px 0 0;
}

.nextArrowItem .arrow {
  margin-left: 10px;
  margin-right: 0;
}
