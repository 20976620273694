.wrapper {
  align-items: center;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  display: flex;
}

@media (width <= 768px) {
  .wrapper {
    top: 5px;
  }
}

@media (width >= 569px) {
  .wrapper {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 36px;
  }
}

.contentWrapper {
  flex: 2 2 auto;
  align-items: center;
  display: flex;
  position: relative;
}

.inputWrapper {
  width: 100%;
}

.iconWrapper {
  width: 24px;
  height: 24px;
  margin-right: 9px;
}
