:local .dropdownOptionsWrapper {
  display: flex;
  flex-direction: column;
}
:local .filteredOptionsWrapper {
  flex-grow: 1;
  overflow-y: scroll;
}
:local .inputIconImage {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  max-height: 100%;
}