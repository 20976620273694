:local .decoratorWrapper {
  display: flex;
  align-items: center;
}
:local .loadSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: margin-right 0.1s ease;
}
:local .marginRight {
  margin-right: 9px;
}
:local .clearable {
  padding: 9px;
  min-width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
}
:local .clearable svg {
  width: 10px;
  fill: #888;
}
:local .clearableEnabled {
  cursor: pointer;
}
:local .clearableEnabled:hover svg {
  fill: #222;
}
:local .nativeClickthrough {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
}