.fixedMaxWidth {
  width: 94%;
  max-width: 1440px;
  margin: 0 auto;
}

.title {
  letter-spacing: -.5px;
  text-align: left;
  width: 100%;
  margin: 54px auto 36px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

@media (width <= 568px) {
  .title {
    letter-spacing: -.5px;
    margin: 36px auto 27px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }
}

.saveCancelButtons {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.saveCancelButtons :first-child {
  margin-right: 5px;
}

.saveCancelButtons :last-child {
  margin-left: 5px;
}

.saveCancelButton {
  flex: 50%;
}

.viewAllWrapper {
  justify-content: center;
  width: 100%;
  margin: 27px auto 49px;
  display: flex;
}

.viewAllWrapper:after {
  content: "";
  background: #ddd;
  width: 100%;
  height: 1px;
  margin-top: 32px;
  position: absolute;
  left: 0;
}

.viewAllButton {
  z-index: 1;
  background: #fff;
  border: 1px solid #ddd;
  padding: 4.5px;
}

.overviewAwardsWeblinksTitle {
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

@media (width <= 768px) {
  .overviewAwardsWeblinksTitle {
    font-size: 16px;
    font-weight: 300;
  }
}
