.container.large {
  flex: 1;
}

.innerContainer {
  text-decoration: none;
  background: #fff;
  display: block;
  height: 100%;
  margin: 0 auto;
  width: 100%;
}
.innerContainer.large {
  max-width: 100%;
}
@media (max-width: 768px) {
  .innerContainer {
    max-width: 100%;
  }
}

.detailsWrapper {
  min-height: 90px;
  padding: 9px 15px 9px;
  position: relative;
}

.title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  text-align: center;
}
@media (max-width: 568px) {
  .title {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.5px;
  }
}