:local .decoratorWrapper {
  align-items: center;
  display: flex;
}

:local .loadSpinner {
  justify-content: center;
  align-items: center;
  transition: margin-right .1s;
  display: flex;
}

:local .marginRight {
  margin-right: 9px;
}

:local .clearable {
  pointer-events: auto;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  padding: 9px;
  display: flex;
}

:local .clearable svg {
  fill: #888;
  width: 10px;
}

:local .clearableEnabled {
  cursor: pointer;
}

:local .clearableEnabled:hover svg {
  fill: #222;
}

:local .nativeClickthrough {
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
