:local .dropdownOptionsWrapper {
  flex-direction: column;
  display: flex;
}

:local .filteredOptionsWrapper {
  flex-grow: 1;
  overflow-y: scroll;
}

:local .inputIconImage {
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
