:local .selectWrapper {
  height: 100%;
  overflow: hidden;
}

:local .selectWrapper select::-ms-expand {
  display: none;
}

:local .select {
  color: #222;
  appearance: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  flex: 1;
  width: 100%;
  height: 100%;
  padding-right: 45px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  transition: padding-top .1s;
  overflow: hidden;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .select:-ms-expand {
  display: none;
}

:local .select:disabled {
  cursor: not-allowed;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .withFakeNativePlaceholder {
  color: #888;
}

:local .selectAnimatedPlaceholderPadding {
  padding-top: 15px;
}

:local .twoDecoratorPadding {
  padding-right: 63px;
}

:local .threeDecoratorPadding {
  padding-right: 81px;
}
