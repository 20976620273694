.wrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  background-color: #f3f3f3;
  border-bottom: thin solid #ddd;
}
.wrapper.isEditMode {
  background-color: #fff;
}

.firmInfoContainer {
  background-position: center !important;
  background-size: cover !important;
  position: relative;
  width: 100%;
  z-index: 2;
}

.headerContent {
  composes: fixedMaxWidth from "./styles.scss";
  position: relative;
  z-index: 2;
  padding: 10px 0 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
@media (max-width: 568px) {
  .headerContent {
    padding: 0;
  }
}

.uploadIconWrapper {
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute !important;
  right: 0;
}
@media (max-width: 568px) {
  .uploadIconWrapper {
    margin-top: 7px;
    width: 100%;
    justify-content: center;
  }
}

.imageUploadSvg {
  height: 30px;
  width: 30px;
  margin-left: 9px;
  margin-bottom: 9px;
}

.photoOverlay {
  background: url("https://a.1stdibscdn.com/assets/images/profile/darken_overlay_tile.png") repeat;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.splashPhotoError {
  font-size: 16px;
  font-weight: 300;
  position: absolute;
  top: 27px;
  bottom: 27px;
  left: 27px;
  right: 27px;
  color: #fff;
  text-align: center;
}