.container {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  min-height: 280px;
  display: flex;
  position: relative;
}

@media (width <= 568px) {
  .container {
    max-width: 100%;
  }
}

.containerEditable {
  composes: container;
  height: 100%;
}

.containerEditable .editIcon {
  visibility: visible;
}

.containerEditable.large {
  max-width: 100%;
  margin: 0;
}

@media (width <= 568px) {
  .containerEditable.large {
    margin: 9px 0;
  }
}

.innerContainer {
  flex-flow: column;
  align-items: stretch;
  width: 100%;
  min-width: 200px;
  height: 100%;
  margin: 9px auto;
  display: flex;
  position: relative;
}

.innerContainer.large {
  max-width: 100%;
}

@media (width <= 568px) {
  .innerContainer {
    max-width: 100%;
    margin: 0;
  }
}

.innerContainerDraggable {
  composes: innerContainer;
  cursor: move;
}

.statusWrapper {
  margin-top: 9px;
}

.imageContainer {
  position: relative;
}

.imageOverlay {
  color: #fff;
  opacity: 0;
  background-color: #222;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  transition: all .3s ease-in;
  display: flex;
  position: absolute;
  top: 0;
}

.imageOverlay:hover {
  opacity: .7;
  color: #fff;
}
